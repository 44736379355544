<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { http } from '@/helpers/http'
import { config } from "@/helpers/config";

/**
 * Saas Dashboard Component
 */
export default {
	page: {
		title: 'Home',
		meta: [{ name: 'description', content: appConfig.description }]
	},
	components: { PageHeader },
	created() {
		this.getAnalytics()
	},
	destroyed() {},
	data() {
		return {
			analytics: null,
			ticketCountChart: {
				chartOptions: {
					chart: {
						height: 380,
						width: '100%',
						type: 'area'
					},
					xaxis: {
						type: 'datetime'
					},
					yaxis: {
						labels: {
							formatter: function(val) {
								return val.toFixed(0)
							}
						}
					}
				}
			},
			channelPieChart: {
				chartOptions: {
					chart: {
						type: 'donut',
						height: 380
					},
					labels: [],
					colors: ['#556ee6', '#34c38f', '#f46a6a'],
					legend: {
						show: true,
						position: 'bottom',
						horizontalAlign: 'center',
						verticalAlign: 'middle',
						floating: false,
						fontSize: '18px',
						offsetX: 0,
						offsetY: 0
					},
					responsive: [
						{
							breakpoint: 600,
							options: {
								chart: {
									height: 240
								},
								legend: {
									show: false
								}
							}
						}
					]
				}
			},
			title: 'Home',
			submitted: false
		}
	},
	methods: {
		async getAnalytics() {
			try {
				this.analytics = await http.get('metrics/home').json()
				this.channelPieChart.chartOptions.labels = this.analytics.channelCountNames
				localStorage.setItem(config.storage.NEW_TICKET_COUNT, this.analytics.newTickets)
			} catch (e) {}
		},
		async endDemo(key) {
			http.post(`settings/endDemo/${key}`)
				.then((response) => response.json())
				.then((response) => {
				})
		}
	}
}
</script>

<template>
	<div>
		<PageHeader :title="title" :items="items" />
		<b-alert show variant="warning" >
			<i class="mdi mdi-alert-circle-outline mr-2"></i>
			<a class="alert-link" v-on:click="endDemo('DEMO_FRONTPAGE')"> Press here</a> to dismiss the demo mode.
		</b-alert>
		<div class="row">
			<div class="col-xl-8">
				<div class="row">
					<div class="col-sm-4">
						<div class="card">
							<div class="card-body">
								<div class="d-flex align-items-center mb-3">
									<div class="avatar-xs mr-3">
										<span
											class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
										>
											<i class="bx bxs-coupon"></i>
										</span>
									</div>
									<h5 class="font-size-14 mb-0">Tickets</h5>
								</div>
								<div class="text-muted mt-4">
									<h4>
										{{ analytics.tickets }}
										<i
											v-if="analytics.ticketsPercentage > 0"
											class="mdi mdi-chevron-up ml-1 text-success"
										></i>
										<i
											v-else-if="analytics.ticketsPercentage < 0"
											class="mdi mdi-chevron-down ml-1 text-danger"
										></i>
									</h4>
									<div class="d-flex">
										<span
											v-if="analytics.ticketsPercentage > 0"
											class="badge badge-soft-success font-size-12"
											>+{{ analytics.ticketsPercentage }}%</span
										>
										<span
											v-else-if="analytics.ticketsPercentage === 0"
											class="badge badge-soft-warning font-size-12"
											>0%</span
										>
										<span v-else class="badge badge-soft-danger font-size-12">
											-{{ Math.abs(analytics.ticketsPercentage) }}%</span
										>
										<span class="ml-2 text-truncate">From previous week</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-sm-4">
						<div class="card">
							<div class="card-body">
								<div class="d-flex align-items-center mb-3">
									<div class="avatar-xs mr-3">
										<span
											class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
										>
											<i class="bx bx-pie-chart"></i>
										</span>
									</div>
									<h5 class="font-size-14 mb-0">Response time</h5>
								</div>
								<div class="text-muted mt-4">
									<h4>
										{{ analytics.responseTimeText }}
										<i
											v-if="analytics.responseTimePercentage > 0"
											class="mdi mdi-chevron-down ml-1 text-success"
										></i>
										<i
											v-else-if="analytics.responseTimePercentage < 0"
											class="mdi mdi-chevron-up ml-1 text-danger"
										></i>
									</h4>
									<div class="d-flex">
										<span
											v-if="analytics.responseTimePercentage > 0"
											class="badge badge-soft-success font-size-12"
											>-{{ analytics.responseTimePercentage }}%</span
										>
										<span
											v-else-if="analytics.responseTimePercentage === 0"
											class="badge badge-soft-warning font-size-12"
											>0%</span
										>
										<span v-else class="badge badge-soft-danger font-size-12">
											+{{ Math.abs(analytics.responseTimePercentage) }}%</span
										>
										<span class="ml-2 text-truncate">From previous week</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-sm-4">
						<div class="card">
							<div class="card-body">
								<div class="d-flex align-items-center mb-3">
									<div class="avatar-xs mr-3">
										<span
											class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
										>
											<i class="bx bx bx-badge-check"></i>
										</span>
									</div>
									<h5 class="font-size-14 mb-0">Cusomer rating</h5>
								</div>
								<div class="text-muted mt-4">
									<h4>
										{{ analytics.customerRating }}
										<i
											v-if="analytics.customerRatingPercentage > 0"
											class="mdi mdi-chevron-up ml-1 text-success"
										></i>
										<i
											v-else-if="analytics.customerRatingPercentage < 0"
											class="mdi mdi-chevron-down ml-1 text-danger"
										></i>
									</h4>
									<div class="d-flex">
										<span
											v-if="analytics.customerRatingPercentage > 0"
											class="badge badge-soft-success font-size-12"
											>-{{ analytics.customerRatingPercentage }}%</span
										>
										<span
											v-else-if="analytics.customerRatingPercentage === 0"
											class="badge badge-soft-warning font-size-12"
											>0%</span
										>
										<span v-else class="badge badge-soft-danger font-size-12">
											+{{
												Math.abs(analytics.customerRatingPercentage)
											}}%</span
										>
										<span class="ml-2 text-truncate">From previous week</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end row -->
			</div>
		</div>
		<div class="row">
			<div class="col-xl-8">
				<div class="card">
					<div class="card-body">
						<apexchart
							class="apex-charts"
							dir="ltr"
							height="320"
							type="area"
							:series="analytics.series"
							:options="ticketCountChart.chartOptions"
						></apexchart>
					</div>
				</div>
			</div>

			<div class="col-xl-4">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title mb-4">Tickets by channel</h4>

						<div>
							<apexchart
								class="apex-charts"
								dir="ltr"
								height="310"
								:series="analytics.channelCountNumbers"
								:options="channelPieChart.chartOptions"
							></apexchart>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end row -->
</template>
